<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tcb_report.product_supply_and_contract_report')}}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
            <b-row class="mt-4">
              <b-col lg="4" sm="4">
                <ValidationProvider name="Fiscal Year id" vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-for="fiscal_year_id"
                    slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                          {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                    <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      @change="fiscalYearCahnge($event)"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4">
                <ValidationProvider name="From date" vid="from_date" rules="required" v-slot="{ errors }">
                  <b-form-group label-for="from_date">
                    <template v-slot:label>
                      {{ $t('globalTrans.date_from') }} <span class="text-danger">*</span>
                    </template>
                    <date-picker
                      id="from_date"
                      class="form-control"
                      v-model="search.from_date"
                      :class="errors[0] ? 'is-invalid' : ''"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('globalTrans.select')"
                      :config="dateConfig"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4">
                <ValidationProvider name="To date" vid="to_date" rules="required" v-slot="{ errors }">
                  <b-form-group label-for="to_date">
                    <template v-slot:label>
                      {{ $t('globalTrans.date_to') }} <span class="text-danger">*</span>
                    </template>
                    <date-picker
                      id="to_date"
                      class="form-control"
                      v-model="search.to_date"
                      :class="errors[0] ? 'is-invalid' : ''"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('globalTrans.select')"
                      :config="dateConfig"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row class="text-right">
              <b-col>
                  <b-button type="submit" variant="success" class="mr-2 btn-sm">{{$t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>
    <body-card style="overflow-x: clip;">
        <b-row class="text-right mb-5 mt-5">
          <b-col>
              <b-button variant="primary" @click="pdfExport" class="mr-2">
                    {{  $t('globalTrans.print') }}
              </b-button>
          </b-col>
        </b-row>
      <!-- table section start -->
       <b-overlay :show="loader">
      <div>
        <report-heading/>
        <b-row>
        <b-col>
          {{ $t('globalTrans.fiscal_year') }}: <strong>{{getFiscalYear(search.fiscal_year_id)}}</strong>
        </b-col>
        <b-col>
          {{ $t('globalTrans.date_from') }}: <strong> {{ search.from_date|dateFormat }} </strong>
        </b-col>
        <b-col>
          {{ $t('globalTrans.date_to') }}: <strong> {{ search.to_date|dateFormat }} </strong>
        </b-col>
      </b-row>
        <table style="width:100%;padding:10px;margin-top:10px;">
          <template>
            <tr>
              <th colspan="7" style="text-align: center;">{{ $t('tcb_report.product_supply_and_contract_report') }}</th>
            </tr>
            <tr>
              <th>{{$n(1)}}</th>
              <th>{{$n(2)}}</th>
              <th>{{$n(3)}}</th>
              <th>{{$n(4)}}</th>
              <th>{{$n(5)}}</th>
              <th>{{$n(6)}}</th>
            </tr>
            <tr>
              <th>{{$t('eBizConfig.product_name')}}</th>
              <th>{{$t('tcbconfiguration.supplier_name')}}</th>
              <th>{{$t('tcb_report.product_quantity_contract')}}</th>
              <th>{{$t('tcb_report.today_intake')}}</th>
              <th>{{$t('tcb_report.total_intake')}}</th>
              <th>{{$t('globalTrans.comments')}}</th>
            </tr>
          </template>
          <template v-if="Object.keys(contractData).length > 0">
            <template v-for="(contracts, itemId) in contractData">
              <!-- Use a unique key for the outer loop -->
              <tr v-for="(value, index) in contracts" :key="`${itemId}-${index}`">
                <!-- Calculate rowspan dynamically based on contracts length -->
                <td :rowspan="contractData[itemId].length" v-if="index === 0">{{ getItemName(itemId) }}</td>
                <td>{{ $i18n.locale === 'bn' ? value.supplier_name_bn : value.supplier_name }}</td>
                <!-- <td class="text-right">{{ itemId == 10 ? value.item_quantity : parseFloat(value.item_quantity)/1000 }}</td> -->
                <td class="text-right">{{ value.item_quantity }}</td>
                <td class="text-right">{{ value.todayTake}}</td>
                <td class="text-right">{{ value.totalTake}}</td>
                <td></td>
              </tr>
              <!-- Add a row for the total item quantity -->
              <tr :key="`${itemId}-total`">
                <td></td>
                <td class="text-right">{{ $t('globalTrans.total') }} = </td>
                <td class="text-right"><b>{{ itemTotals[itemId] }}</b></td>
                <td class="text-right"><b>{{ toadyTakeTotal[itemId] }}</b></td>
                <td class="text-right"><b>{{ sumTotalTake[itemId] }}</b></td>
                <td></td>
              </tr>
            </template>
        </template>
        <template v-else>
            <tr>
              <td colspan="6"> {{ $t('globalTrans.noDataFound') }}</td>
            </tr>
        </template>
        </table>
      </div>
        </b-overlay>
    </body-card>
  </div>
</template>
<script>
import ModalBaseMasterList from '@/mixins/list'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { productContractSupplyReport } from '../../api/routes'
import ReportHeading from '../../components/ReportHeading.vue'
// import Pdf from './pdf'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ReportHeading
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        from_date: '',
        to_date: '',
        month: 0,
        regional_office: 0,
        item: 0,
        warehouseList: [],
        item_category: 0
      },
      dateConfig: { static: true },
      contractData: [],
      itemList: [],
      fiscalYear: {},
      show: false,
      loader: false
    }
  },
  created () {
    this.search.fiscal_year_id = this.currentFiscalYearId
    this.fiscalYearCahnge(this.currentFiscalYearId)
  },
  watch: {
    'search.item_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.itemList = this.$store.state.LicenseRegistrationService.commonObj.itemList.filter(item => item.status === 1 && item.item_category_id === newVal)
      }
    }
  },
  computed: {
    regionalOfficeList (orgId = null) {
      return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.org_id === 7 && item.is_regional_office === 1)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: this.EngBangNum(item.text_bn) }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    MonthList () {
      return this.$store.state.commonObj.monthList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    itemCategoryList () {
      return this.$store.state.LicenseRegistrationService.commonObj.itemCategoryList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    currentFiscalYearId () {
      return this.$store.state.currentFiscalYearId
    },
    itemTotals () {
      const totals = {}
      for (const itemId in this.contractData) {
        totals[itemId] = this.contractData[itemId].reduce((sum, contract) => {
          return sum + contract.item_quantity
        }, 0)
      }
      return totals
    },
    toadyTakeTotal () {
      const totals = {}
      for (const itemId in this.contractData) {
        totals[itemId] = this.contractData[itemId].reduce((sum, contract) => {
          return sum + contract.todayTake
        }, 0)
      }
      return totals
    },
    sumTotalTake () {
      const totals = {}
      for (const itemId in this.contractData) {
        totals[itemId] = this.contractData[itemId].reduce((sum, contract) => {
          return sum + contract.totalTake
        }, 0)
      }
      return totals
    }
  },
  methods: {
    getRowspan (itemId) {
      return this.contractData[itemId].length
  },
    totalConslussion (data) {
      let total = 0
      data.map(function (report, index) {
        total += report.total_stock_in - (report.total_stock_out + report.total_stock_out_pending + report.total_on_transport + report.total_truck_distribution + report.total_store_distribution)
      })
      return total
    },
      async searchData () {
        this.loader = true
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadingState = { loading: false, listReload: false }
          result = await RestApi.getData(licenseRegistrationServiceBaseUrl, productContractSupplyReport, this.search)
          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)

          if (result.success) {
            this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
            this.loader = false
            this.show = true
            this.contractData = result.data
          } else {
            this.loader = false
            this.contractData = {}
            this.$refs.form.setErrors(result.errors)
          }
        }
      },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, productContractSupplyReport, params)
      var blob = new Blob([result], {
            type: 'application/pdf'
        })
        this.loading = false
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getItemName (catId) {
          const cateObj = this.$store.state.LicenseRegistrationService.commonObj.itemList.find(item => item.value === parseInt(catId))
          if (cateObj !== undefined) {
              if (this.$i18n.locale === 'bn') {
                  return cateObj.text_bn
              } else {
                  return cateObj.text_en
              }
          }
      },
    getOfficeName (id) {
      const data = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    getMonthName (id) {
      const data = this.$store.state.commonObj.monthList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    getFiscalYear (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    fiscalYearCahnge (event) {
      this.fiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === event)
      this.search.start_date = this.fiscalYear.start_date
      this.search.end_date = this.fiscalYear.end_date
      this.dateConfig = Object.assign({}, this.dateConfig, { minDate: this.fiscalYear.start_date, maxDate: this.fiscalYear.end_date })
    },
    EngBangNum (n) {
        if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
        } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
        }
      }
    // pdfExport () {
    //   const reportTitle = this.$t('li_step.expiration_passed_report')
    //   Pdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this.contractData, this, this.search)
    // }
  }
}
</script>
<style>
@import '../../style.css';
</style>
